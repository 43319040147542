export const Mosaic = [
  {
    title: "Peiman Zhiani",
    img: "Peiman.jpg",
    linkedin: "https://www.linkedin.com/in/peiman-zhiani/",
    lastPosition: "Developer",
    role:"Senior Developer"
  },
  {
    title: "Johnson Luong",
    img: "Johnson.jpg",
    linkedin: "https://www.linkedin.com/in/johnson-luong/",
    lastPosition: "Developer",
    role:"Senior Developer"
  },
  {
    title: "Duy Nguyen",
    img: "Duy.jpg",
    linkedin: "https://www.linkedin.com/in/duwe-ng/",
    lastPosition: "Developer",
    role:"AI Developer"
  },
  {
    title: "Zahila Rehman",
    img: "Zahila.jpg",
    linkedin: "https://www.linkedin.com/in/zahila-rehman-9118a4217/",
    lastPosition: "Lead",
    role:"Project Manager"
  },
  {
    title: "Kasey Le",
    img: "blueprint.jpg",
    linkedin: "https://www.linkedin.com/in/kasey-le-43aa31255/",
    lastPosition: "Designer",
    role:"Designer"
  },
  {
    title: "Alison Lu",
    img: "Alison.jpg",
    linkedin: "https://www.linkedin.com/in/alisonlu3/",
    lastPosition: "Designer",
    role:"Designer"
  },
  {
    title: "Manpreet Sarna",
    img: "blueprint.jpg",
    linkedin: "",
    lastPosition: "Designer",
    role:"Designer"
  },
  {
    title: "Kian Hoseinkhani",
    img: "Kian.jpg",
    linkedin: "https://www.linkedin.com/in/kian-hosseinkhani-716a311b6/",
    lastPosition: "Lead",
    role:"Project Manager"
  },
  {
    title: "Enoch Muwanguzi",
    img: "blueprint.jpg",
    linkedin: "https://www.linkedin.com/in/enoch-muwanguzi-42491425b/",
    lastPosition: "Developer",
    role:"AI Developer"
  },
  {
    title: "Derek Huang",
    img: "Derek.jpg",
    linkedin: "https://www.linkedin.com/in/derekhuang1/",
    lastPosition: "Developer",
    role:"Junior Developer"
  },
  {
    title: "Fabian Siswanto",
    img: "Fabian.jpg",
    linkedin: "https://www.linkedin.com/in/fabiansiswanto/",
    lastPosition: "Developer",
    role:"Junior Developer"
  },
  {
    title: "Kousha Amouzesh",
    img: "blueprint.jpg",
    linkedin: "https://www.linkedin.com/in/kousha-amouzesh/",
    lastPosition: "Lead",
    role:"Tech Lead"
  },
  {
    title: "Iris Au-Yeung",
    img: "blueprint.jpg",
    linkedin: "https://www.linkedin.com/in/irisau-yeung/",
    lastPosition: "Designer",
    role:"Designer"
  },
  {
    title: "Lauren Yip",
    img: "blueprint.jpg",
    linkedin: "https://www.linkedin.com/in/lauren-yip/",
    lastPosition: "Developer",
    role:"Junior Developer"
  },
  {
    title: "Teeya Li",
    img: "blueprint.jpg",
    linkedin: "",
    lastPosition: "Developer",
    role:"Junior Developer"
  },
]