export const Pedals = [
  
  {
    title: "Amy Zhao",
    img: "Amy.jpg",
    linkedin: "https://www.linkedin.com/in/amy-zhao-/",
    lastPosition: "Designer",
    role:"Designer"
  },
  {
    title: "Ayana Hussain",
    img: "Ayana.jpg",
    linkedin: "https://www.linkedin.com/in/ayana-hussain-a3399a227/",
    lastPosition: "Developer",
    role:"Senior Developer"
  },
  {
    title: "Vinh Dang",
    img: "blueprint.jpg",
    linkedin: "https://www.linkedin.com/in/quang-vinh-dang-788ab0194/",
    lastPosition: "Developer",
    role:"Senior Developer"
  },
  {
    title: "Eugene Zhu",
    img: "Eugene.jpg",
    linkedin: "https://www.linkedin.com/in/eugenezhu/",
    lastPosition: "Designer",
    role:"Designer"
  },
  {
    title: "Kate Simone",
    img: "Kate.jpg",
    linkedin: "https://www.linkedin.com/in/kate-simone-866335284/",
    lastPosition: "Designer",
    role:"Designer"
  },
  {
    title: "Arthur Zhang",
    img: "Arthur.jpg",
    linkedin: "https://www.linkedin.com/in/arthur0z/",
    lastPosition: "Developer",
    role:"Senior Developer"
  },{
    title: "Ronney Lok",
    img: "Ronney.jpg",
    linkedin: "https://www.linkedin.com/in/ronneylok/",
    lastPosition: "Developer",
    role:"Senior Developer"
  },{
    title: "Sallin Koutev",
    img: "Sallin.jpg",
    linkedin: "https://www.linkedin.com/in/sallin-koutev-216b0016b/",
    lastPosition: "Lead",
    role:"Tech Lead"
  },{
    title: "Caitlin Syho",
    img: "Caitlin.jpg",
    linkedin: "https://www.linkedin.com/in/caitlinsyho/",
    lastPosition: "Lead",
    role:"Product Lead"
  },{
    title: "Hinako Kamiya",
    img: "Hinako.jpg",
    linkedin: "https://www.linkedin.com/in/hinako-kamiya/",
    lastPosition: "Lead",
    role:"Project Manager"
  },{
    title: "Duc Dang",
    img: "blueprint.jpg",
    linkedin: "https://www.linkedin.com/in/ddang2001/",
    lastPosition: "Lead",
    role:"Tech Lead"
  },
  {
    title: "Tomi Lui",
    img: "blueprint.jpg",
    linkedin: "https://www.linkedin.com/in/tomi-lui-3955631b3/",
    lastPosition: "Developer",
    role:"Senior Developer"
  },
  {
    title: "Terry Kwok",
    img: "Terry.jpg",
    linkedin: "https://www.linkedin.com/in/lai-chit-kwok-6074472b5/",
    lastPosition: "Developer",
    role:"Junior Developer"
  },
  {
    title: "Supriya Dua",
    img: "Supriya.jpg",
    linkedin: "https://www.linkedin.com/in/supriya-dua-a8a576204/",
    lastPosition: "Developer",
    role:"Junior Developer"
  },
  {
    title: "Kumar Shivam",
    img: "blueprint.jpg",
    linkedin: "https://www.linkedin.com/in/kumarshivam0002/",
    lastPosition: "Lead",
    role:"Tech Lead"
  },
  {
    title: "Monishka Gautam",
    img: "blueprint.jpg",
    linkedin: "https://www.linkedin.com/in/monishka-gautam/",
    lastPosition: "Developer",
    role:"Junior Developer"
  },
  
]